@import './shared.scss';
@import "./breakpoints";
@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
$bg-mb:url(../images/mobile-bg.png);
$loginLogo:url(../images/loginLogo.png);
@import './noauthlayout.scss';
$wallpaper: url('../images/wallpaper.png');
$brain: url('../images/ColorfulBrainDigitalWorldTechnologyLogo1.png');

$headerheight: 0px;//250px;

.login {
  * {
    color:#000;
  }
  .layout-height {
    height: 100vh; /* calc(100vh - #{$headerheight + $footerheight}) */;
  }

  .brain {
    background:#040053 $brain no-repeat center center ; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;  
    background-size: 80%;
  }

  .logo {
    text-align: center;
    margin: 0 auto;
    img {
      height: 220px;
    }
  }

  .flex-container {
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .flex-item {
    padding: 5px;
    margin: 10px;
  }

  input.form-control {
    height: 75px;
    font-size: 1.5rem;
  }

  .inputarea {
    margin: 0 auto;
  }

  .forgetlink {
    color: #6C6C6C;
    font-size: .975rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .passwordreminder {
    margin: 27px 0 0 40px;
    font-size: .975rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

// .layout-area {
//   .form.login {
//     .mb-3.forget_remember {
//       max-width: inherit;
//       min-width: inherit;
//     }
//     .passwordreminder {
//       max-width: 540px;
//       height: 70px;
//       margin: 0 auto;
//       .rememberme {
//         float: left;
//         width: 50%;
//         label {
//           color: #FFF;
//           font-family: 'Inter', sans-serif;
//           font-size: 20px;
//           font-style: normal;
//           font-weight: 400;
//           line-height: 20px;
//         }
//         @media screen and (max-width: $screen-sm-min) {
//           display: none;
//         }
//       }
//       .forgetlink {
//         width: 50%;
//         float: left;
//         display: block;
//         text-align: right;
//         a {
//           color: #AFF4C6;
//           text-align: center;
//           font-family: 'Inter', sans-serif;
//           font-size: 20px;
//           font-style: italic;
//           font-weight: 700;
//           line-height: normal;          
//           text-decoration: none;
//         }

//         @media screen and (max-width: $screen-sm-min) {
//           width: 100%;
//           float: initial;
//           text-align: right;
//           margin-left: -25px;
//           a {
//             color: #023D1A;
//             font-family: 'Inter', sans-serif;
//             font-size: 15px;
//             font-style: italic;
//             font-weight: 700;
//             line-height: normal;            
//           }
//         }
//       }
//       @media screen and (max-width: $screen-sm-min) {
//         margin-top:-10px;
//         height: 45px;
//       }
//     }
//     .login {
//       button {
//         text-align: center;
//         font-family: 'Inter', sans-serif;
//         font-size: 24px;
//         font-style: normal;
//         font-weight: 900;
//         text-transform: capitalize;
//         text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);;
//         display: flex;
//         flex-direction: row;
//         justify-content: center;
//         flex-shrink: 0;
//         padding: 0 40px;
//         background-color: #3D97EB;
//         border-color: #3D97EB;
//         @media screen and (max-width: $screen-sm-min) {
//           color: white;      
//           font-size: 24px;
//         }
//       }
//       .btn:hover {
//         background-color: #2989e4;
//       }
//     }
//   }
// }

// .passwordicon {
//   transform: rotate(-45deg);
//   font-size: 20px;
// }

// .googlelogin > div {
//   margin: 41px 0;
//   width: 215px;
// }

// .microsoftlogin > svg {
//   margin: 30px 0;
//   width: 215px;
// }