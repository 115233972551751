// $large-logo: '../images/logo-lg-white.png';
// $small-logo: '../images/logo-sm-white.png';

$small-logo-width: 80px;
$small-logo-height: 80px;

$large-logo-width: 200px;
$large-logo-height: 65px;
$primary-color:#548235;
$secondary-color: #FF9D42;
$input-background:#F3F3F3;
$header-color:#70AD47;

$button-secondary-color:#fff;
$circle-border-radius:15px;
:export {
  largeWidth : $large-logo-width;
  largeHeight : $large-logo-height;
  smallWidth : $small-logo-width;
  smallHeight : $small-logo-height;
}