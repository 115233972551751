@import './_variables.scss';

.landing {
  .card {
    max-width: 300px;
    min-height: 200px;
  }
  .card-body {
    padding:0;
  }
  .card-title {
    background-color: $header-color;
    min-height: 100px;
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin:0;
    letter-spacing: 1.6px    
  }
}