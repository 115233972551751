@import './_variables.scss';

$font-size:1.24em;
$button-font-size:1.875em;

input.form-control {
  border-radius: 4px;
  background: $input-background;
  height: 50px;
  flex-shrink: 0;
  border-color: $input-background;
  color: #737373;
  font-family: 'Inter', sans-serif;
  font-size: $font-size;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

textarea.form-control {
  border-radius: 4px;
  background: $input-background;
  flex-shrink: 0;
  border-color: $input-background;
  color: #737373;
  font-family: 'Inter', sans-serif;
  font-size: $font-size;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


label.form-label {
  color: #000;
  font-family: 'Inter', sans-serif;
  font-size: $font-size;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12rem;
}

.btn-primary, button.btn-primary {
  background-color: #548235;
  font-family: 'Inter', sans-serif;
  color: #FFF;
  font-size: $button-font-size;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  padding: 20px 38px;
  border-radius: 3px;
}
.form-label {
  font-size: 1.2em;
  color: #444444;
}
