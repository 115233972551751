@import "./breakpoints";
@import "./forms";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

$background: #3e579e;

$headerheight:160px;
$footerheight: 70px;
//$logo: '../images/logo-lg-white.png';

* {
  font-family: 'Inter', sans-serif;
}

header.container {
  height:$headerheight;
}
.layout-height {
  min-height: calc(100vh - #{$headerheight + $footerheight});
}
nav.navbar {
  padding:0;
}
/*
@media screen and (max-width: $screen-sm-min) {
  //$logo: '../../images/logo-sm-white.png';
  $headerheight: 109px;
  header.container {
    height:$headerheight;
  }
  .layout-height {
    height: calc(100vh - #{$headerheight});
  }
}
*/
header {
  height: $headerheight;
  border-bottom: 1px solid rgb(202, 202, 202);
  background-color: #70AD47;
  .logo {
    width: 200px;
    height: 65px;
  }
  .innerlogo {
    display: block;
    margin:10px 0 0 0;
    > img {
      height: 140px;
    }
  }

  @media screen and (max-width: $screen-sm-min) {
    height: $headerheight;

    .logo {
      width: 80px;
      height: 80px;
    }
    .logo-offset {
      padding-top:10px;
    }
  }
}

footer {
  height: 70px;
}

.container {
  width: 100%;
  min-width: 100%;
  max-width: 100%;

  .button {
    background: red;
  }

  &.red {
    background: red;
  }
  
  @media screen and (min-width: $screen-sm-min) {
    //width: 50%;

    .button {
      background: green;
    }

    &.red {
      background: darken(red, .15);
      width: 75%;
    }
  }
}

.half-height {
  min-height: calc(50vh - #{$headerheight});
}

.loading-indicator:before {
  content: '';
  background: #000000cc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  display: block;
}

.loading-indicator:after {
  content: 'Please wait';
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 1001;
  color:white;
  text-align:center;
  font-weight:bold;
  font-size:1.5rem;        
  display: block;
}
.loading-indicator .spinner-border {
  position: fixed;
  top:calc(50% + 3rem);
  left: calc(50% - 1rem);
  color:white;
  text-align:center;
  font-weight:bold;
  z-index: 1002;
}
.toast-location {
  position: absolute;
  top:50%;
  left: calc(50% - 200px);
  z-index: 1002;
}

h1 {
  color: #000;
  font-size: 3.375rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;  
}

h2 {
  color: #000;
  font-size: 1.5rem !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: normal !important;  
}
h3 {
  color: #000;
  font-size: 1.25rem !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: normal !important; 
}
.mainmenu {
  margin-top:57px;
  button {
    :active {
      background: none;
    }
    color: #FFF;
    border: none;
    background: none;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    svg {
      margin-top:-5px;
      font-size: 1.975rem;
    }
  }
}
.company-text {
  text-align: right;
  h3 {
    margin-top:37px;
    text-align: left;
  }
}
.company-logo {
  text-align: left;
  img {
    height:120px;
  }
}