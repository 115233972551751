@import './_variables.scss';

.casesdraft {
  .caseslist > table {
    height:220px;
  }
  .label {
    text-align: right;
    margin-top:6px;
  }
  .text-muted {
    margin:0 0 10px 0;
    display: block;
  }
  button.add {
    background:none;
    color:$primary-color;
    padding: 0;
    border-radius: $circle-border-radius;
    margin-top:-5px !important;
    margin-left: 30px !important;
  }
  button.add:active {
    background:none;
    color:$button-secondary-color;
    border-radius: $circle-border-radius;
  }
  button.add:active > svg{
    background:$primary-color;
    color:$button-secondary-color;
    border-radius: $circle-border-radius;
  }
  button.del {
    background: $input-background;
    color: $secondary-color;
    padding: 13px 21px;
    border: none;
    font-size: 1.125em;
    font-weight: 400;
    margin: -49px 230px 0 0;
    position: absolute;
    right: 0;
  }
  button.del:active {
    background-color:$secondary-color;
  }
}